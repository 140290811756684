/*eslint-disable */
<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">
              {{ cvCardTitle }}
            </h4>
          </template>
          <template>
            <p v-if="!propOpenedInModal">
              {{ cvCardSubHeader }}
            </p>
            <form action="#">
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label
                    for="validationinterest_name">
                    {{cvInterestNameLabel}}</label>
                  <input
                    v-model="vmInterestFormData.interest_name"
                    type="text"
                    class="form-control"
                    required/>
                </div><div class="col-md-12 mb-3">
                  <label
                    for="validationinterest_category_id">
                    {{cvInterestCategoryIdLabel}}</label>
                  <multiselect
                    v-model="vmInterestFormData.interest_category"
                    :tag-placeholder="cvInterestCategoryIdLabel"
                    :placeholder="cvInterestCategoryIdLabel"
                    label="interest_cat_name"
                    track-by="interest_cat_id"
                    :options="interestCategoryOptions"
                    :multiple="false"
                    :taggable="true">
                  </multiselect>
                </div>
              </div>
              <div class="form-group">
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="interestAdd()">
                  {{ cvSubmitBtn }}
                </button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast
      v-model="showToast"
      name="Toast"
      :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
      :variant="toastVariant"
      :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-container>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import Multiselect from "vue-multiselect"
import { Interests } from "../../../FackApi/api/interest"
import ApiResponse from "../../../Utils/apiResponse.js"
import { InterestCategorys } from "../../../FackApi/api/interestCategory"
import { socialvue } from "../../../config/pluginInit.js"
import userPermission from "../../../Utils/user_permission.js"

export default {
  name: "InterestAdd",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Multiselect
  },
  data () {
    return {
      apiName: "interest_add",
      cvCardTitle: "Add Interest",
      cvCardSubHeader: "AddInterest ",
      cvSubmitBtn: "Add",
      cvInterestNameLabel: "Interest",
      cvInterestCategoryIdLabel: "Interest Category",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Interest",
      vmInterestFormData: Object.assign({}, this.initFormData()),
      vmInterestDesc: null,
      vmInterestType: null,
      interestCategoryOptions: []
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  mounted () {
    socialvue.index()
    this.loadRequiredData()
  },
  methods: {
    /**
     * initFormData
     */
    initFormData () {
      return {
        "interest_name": "",
        "interest_category": ""
      }
    },
    /**
     * loadRequiredData
     */
    loadRequiredData () {
      this.interestCategoryList()
    },
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmInterestFormData) {
          if (!this.vmInterestFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
      }
    },
    /**
     * interestAdd
     */
    async interestAdd () {
      // if (!this.validate()) {
      //   return false
      // }
      try {
        let interestAddResp = await Interests.interestAdd(this, this.vmInterestFormData)
        await ApiResponse.responseMessageDisplay(this, interestAddResp)
        if (interestAddResp && !interestAddResp.resp_status) {
          return false
        }
        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseInterestAddModal", interestAddResp.resp_data)
        }
        this.vmInterestFormData = Object.assign({}, this.initFormData())
      }
      catch (err) {
        console.error("Exception occurred at interestAdd() and Exception:", err.message)
      }
    },
    /**
     * interestCategoryList
     */
    async interestCategoryList () {
      try {
        let interestCategoryResp = await InterestCategorys.interestCategoryList(this)
        if (interestCategoryResp && !interestCategoryResp.resp_status) {
          return false
        }
        this.interestCategoryOptions = interestCategoryResp.resp_data.data
      }
      catch (err) {
        console.error("Exception occurred at interestCategoryList() and Exception:", err.message)
      }
    }
  }
}
</script>
